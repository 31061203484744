import React, { Component } from 'react';
import { withFirebase } from './Firebase';

class Tile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            imageInfos: [],
        };
    }

    componentDidMount() {
        const imageInfos = [];
        this.props.firebase.getImagesForSign(this.props.sign.id)
            .then(querySnapshot => querySnapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            }))
            .then(imgs => {
                imgs.forEach(img => {
                    this.props.firebase.getDownloadUrlForImage(img.id)
                        .then(url => {
                            console.info('url', url);
                            let newInfo = {
                                ...img,
                                url: url,
                            };
                            imageInfos.push(newInfo);
                            this.setState({
                                isLoading: false,
                                imageInfos: imageInfos,
                            });
                            console.info('imgs', this.state.imageInfos);
                        },
                            err => console.log("error", err));
                });
            });
    }

    render() {
        return (
            <a className="tile" href={"/pub/" + this.props.sign.pubId}>
                <div className="tile-inner">
                    <div className="tile-front">
                        {
                            this.state.imageInfos && this.state.imageInfos.length > 0 ? (
                                <img className="" src={this.state.imageInfos[0].url} alt={"Image of pub sign: " + this.state.imageInfos[0].description}></img>
                            ) : ""
                        }

                    </div>
                    <div className="tile-back">
                        <h3 className="">{this.props.sign.pubData.name}</h3>
                    </div>
                </div>
            </a>
        );
    }
}

export default withFirebase(Tile);