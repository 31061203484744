import React, { Component } from 'react';
import { withFirebase } from './Firebase';
import * as IMAGE_TYPES from '../constants/image-types';

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
function dateToYearMonth(d) {
    return d.getFullYear() + "-" + pad((d.getMonth() + 1), 2, '0');
}
function dateToYearMonthDate(d) {
    return dateToYearMonth(d) + "-" + pad(d.getDate(), 2, '0');
}
function intToMonthLongform(i) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[i - 1];
}

class PubPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pub: {},
            images: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });

        this.props.firebase.getPub(this.props.match.params.pubId)
            .then(pubDoc => {
                this.setState({
                    pub: {
                        id: pubDoc.id,
                        ...pubDoc.data(),
                    },
                });
                console.log("ss", this.state);

                let fetchedImages = [];
                this.props.firebase.getImagesForPub(pubDoc.id)
                    .then(querySnapshot => querySnapshot.forEach(imageDoc => {
                        let newImage = {
                            id: imageDoc.id,
                            ...imageDoc.data(),
                        };
                        if (newImage.created) {
                            let d = newImage.created.toDate();
                            newImage.createdString = d.getDate() + " " + intToMonthLongform(d.getMonth() + 1) + " " + d.getFullYear();
                        }
                        this.props.firebase.getDownloadURLForImageFilename(newImage.filename)
                            .then(url => {
                                newImage.url = url;
                                fetchedImages.push(newImage);
                                this.setState({
                                    images: fetchedImages,
                                    isLoading: false,
                                });
                                console.log("withImg", this.state);
                            });
                    }));
            });
    }

    render() {
        if (this.state.isLoading) {
            return "";
        }

        let imagesByMonth = {};
        this.state.images.forEach(image => {

            if (image.asOf) {
                let asOf = image.asOf.toDate();
                let asOfMonth = dateToYearMonth(asOf);
                if (!imagesByMonth[asOfMonth]) {
                    imagesByMonth[asOfMonth] = {};
                }
                if (!imagesByMonth[asOfMonth][image.type]) {
                    imagesByMonth[asOfMonth][image.type] = {};
                }
                if (imagesByMonth[asOfMonth][image.type][asOf]) {
                    imagesByMonth[asOfMonth][image.type][asOf].push(image);
                } else {
                    imagesByMonth[asOfMonth][image.type][asOf] = [image];
                }
            }

        });

        console.info("sorted", imagesByMonth);

        return (
            <div className="container mx-auto grid grid-cols-4">
                <div className="col-start-2 col-span-3 mt-6 mx-6">
                    <h1>{this.state.pub.name}</h1>
                </div>
                <div className="col-start-2 col-span-3 mt-6 mx-6 bg-gray-300 pb-3">
                    <p className="mt-3 mx-3 italic">{this.state.pub.address}</p>
                    {this.state.pub.website ? <p className="mt-3 mx-3"><a href={this.state.pub.website}>{this.state.pub.website}</a></p> : ""}
                    <p className="mt-3 mx-3">{this.state.pub.description}</p>
                </div>
                {
                    Object.keys(imagesByMonth).sort().reverse().map(asOfMonth => {
                        return (
                            <div className="col-span-4 mt-6 grid grid-cols-4" key={asOfMonth}>
                                <div className="mx-6 text-center"><h2>{intToMonthLongform(parseInt(asOfMonth.slice(5, 7)))} {asOfMonth.slice(0, 4)}</h2></div>
                                <div className="col-span-3 mx-6">

                                    {imagesByMonth[asOfMonth][IMAGE_TYPES.SIGN_CROPPED] ? (<div> {
                                        Object.keys(imagesByMonth[asOfMonth][IMAGE_TYPES.SIGN_CROPPED]).sort().reverse().map(date => {
                                            let images = imagesByMonth[asOfMonth][IMAGE_TYPES.SIGN_CROPPED][date];
                                            let image = images[0]; /* For now, only include one (the most recent) sign-cropped image */
                                            return (
                                                <div className="grid grid-cols-3" key={image.id}>
                                                    <div className=""><img src={image.url} alt={image.description} /></div>
                                                    <div className="col-span-2 ml-6">
                                                        {image.description ? (<p className="mt-3">{image.description}</p>) : ""}
                                                        {image.createdString ? (<p className="mt-3">Uploaded on {image.createdString}</p>) : ""}
                                                    </div>
                                                </div>);
                                        })
                                    } </div>) : ""}

                                    {imagesByMonth[asOfMonth][IMAGE_TYPES.PUB_FRONT] ? (<div> {
                                        Object.keys(imagesByMonth[asOfMonth][IMAGE_TYPES.PUB_FRONT]).sort().reverse().map(date => {
                                            return (
                                                <div className="mt-3 -ml-3 pr-3 pb-3" key={date}>
                                                    {
                                                        imagesByMonth[asOfMonth][IMAGE_TYPES.PUB_FRONT][date].map(image => {
                                                            return (<img src={image.url} alt={image.description} className="h-48 inline-block mt-3 ml-3 p-3 bg-white border border-gray-600" key={image.id} />);
                                                        })
                                                    }
                                                </div>
                                            );
                                        })
                                    } </div>) : ""}

                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

};

export default withFirebase(PubPage);