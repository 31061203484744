import React from 'react';
import Tiles from './Tiles';

const LandingPage = () => {
    return (
        <div className="container mx-auto grid grid-cols-4">
            <div className="p-6">
                <img src="images/pubsigns-splash.jpg" alt=""></img>
            </div>
            <div className="col-span-3 p-6">
                <h1 className="mb-6">Pub signs <span className="text-3xl">of&nbsp;South&nbsp;East&nbsp;London</span></h1>
                <Tiles />
            </div>
        </div>
    );
};

export default LandingPage;