import React from 'react';

const MapPage = () => {
    return (
        <div className="container mx-auto grid grid-cols-4">
            <div className="p-6">
                <img src="images/pubsigns-splash.jpg" alt=""></img>
            </div>
            <div className="col-span-3 p-6">
                <h1>Map</h1>
            </div>
        </div>
    );
};

export default MapPage;