import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../constants/routes';

const Navigation = () => (
    <div className="bg-blue-900 shadow-lg p-4 sticky top-0">
        <nav className="container mx-auto px-6">
            <ul className="flex flex-row-reverse">
                <li className="ml-4">
                    <Link className="inline-block border border-blue-900 rounded hover:border-blue-400 text-gray-300 py-1 px-3 no-underline" to={ROUTES.ABOUT}>About</Link>
                </li>
                <li className="ml-4">
                    <Link className="inline-block border border-blue-900 rounded hover:border-blue-400 text-gray-300 py-1 px-3 no-underline" to={ROUTES.MAP}>Map</Link>
                </li>
                <li className="ml-4">
                    <Link className="inline-block border border-blue-900 rounded hover:border-blue-400 text-gray-300 py-1 px-3 no-underline" to={ROUTES.LANDING}>Home</Link>
                </li>
            </ul>
        </nav>
    </div>
);

export default Navigation;