import React, { Component } from 'react';
import { withFirebase } from './Firebase';
import Tile from './Tile';

class Tiles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pubSigns: [],
        };
    }

    componentDidMount() {
        this.props.firebase.getMostRecentSigns(12)
            .then(querySnapshot => {
                const fetchedSigns = [];
                querySnapshot.forEach(doc => {
                    let newItem = {
                        id: doc.id,
                        ...doc.data(),
                    };
                    newItem.pub.get().then(pub => {
                        newItem.pubId = pub.id;
                        newItem.pubData = pub.data();
                        fetchedSigns.push(newItem);
                        this.setState({
                            pubSigns: fetchedSigns,
                        });
                        console.log('signs', this.state.pubSigns);
                    });
                });
            });

    }

    render() {
        if (this.state.pubSigns.length < 2) {
            return "Loading";
        }
        return (
            <div className="grid grid-cols-4 grid-rows-3 gap-1">
                <div className="bg-gray-200"></div>
                <Tile key={this.state.pubSigns[0].id} sign={this.state.pubSigns[0]} />
                <div className="bg-orange-600"></div>
                <div></div>

                <div className="bg-orange-300"></div>
                <div className="bg-gray-200"></div>
                <div></div>
                <Tile key={this.state.pubSigns[1].id} sign={this.state.pubSigns[1]} />

                <div></div>
                <div className="bg-gray-700"></div>
                <div className="bg-orange-300"></div>
                <div className="bg-gray-200"></div>
            </div>
        );
    }

}

export default withFirebase(Tiles);