import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import { withFirebase } from './Firebase';
import Naviation from './Navigation';
import LandingPage from './LandingPage';
import PubPage from './PubPage';
import MapPage from './MapPage';
import AboutPage from './AboutPage';

import * as ROUTES from '../constants/routes';

class App extends Component {

  render() {
    return (
      <Router>
        <div>
          <Naviation />

          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route path={ROUTES.PUB} component={PubPage} />
          <Route path={ROUTES.MAP} component={MapPage} />
          <Route path={ROUTES.ABOUT} component={AboutPage} />

          <div className="w-full mt-12 text-center text-xs">Copyright &copy; 2020 <a href="https://howiegoing.com">Howard Buck</a></div>
        </div>
      </Router>
    );
  }
}

export default withFirebase(App);
