import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.firestore = app.firestore();
        this.storage = app.storage();
    }

    authenticateAnonymously = () => {
        this.auth().signInAnonymously();
    };

    getMostRecentSigns = (limit) => this.firestore.collection('signs').orderBy('created', 'desc').limit(limit).get();

    getPub = (pubId) => this.firestore.collection('pubs').doc(pubId).get();

    getSignsForPub = (pubId) => this.firestore.collection('signs').where('pub', '==', pubId);

    getImagesForSign = (signId) => this.firestore.collection('signs').doc(signId).collection('images').orderBy('asOf', 'desc').get();

    getImagesForPub = (pubId) => this.firestore.collection('images').where('pubId', '==', pubId).orderBy('asOf', 'desc').get();

    getImageFilename = (imageId) => this.firestore.collection('images').doc(imageId).get().then(doc => doc.data().filename);

    getDownloadUrlForImage = (imageId) =>
        this.firestore.collection('images').doc(imageId).get()
            .then(doc => doc.data().filename)
            .then(filename => this.storage.ref("images/" + filename).getDownloadURL());

    getDownloadURLForImageFilename = (filename) =>
        this.storage.ref("images/" + filename).getDownloadURL();
}

export default Firebase;
